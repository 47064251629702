import { 
  LOAD_TENANT_ACCOUNT_SYSTEM_REQUEST,
  LOAD_TENANT_ACCOUNT_SYSTEM_SUCCESS,
  LOAD_TENANT_BASIC_REQUEST,
  LOAD_TENANT_BASIC_SUCCESS,
  LOAD_TENANT_CORRESPONDENCE_REQUEST,
  LOAD_TENANT_CORRESPONDENCE_SUCCESS,
  LOAD_TENANT_DEFAULT_REQUEST,
  LOAD_TENANT_DEFAULT_SUCCESS,
  LOAD_TENANT_DETAILS_ERROR,
  LOAD_TENANT_DETAILS_LOADING,
  LOAD_TENANT_FINANCE_REQUEST,
  LOAD_TENANT_FINANCE_SUCCESS,
  LOAD_TENANT_SERVICE_AREA_REQUEST,
  LOAD_TENANT_SERVICE_AREA_SUCCESS,
  LOAD_TENANT_SERVICE_REQUEST,
  LOAD_TENANT_SERVICE_SUCCESS,
  SEND_TENANT_DETAILS_SUCCESS,
  SEND_UPDATE_TENANT_DETAILS_REQUEST,
    } from '../constant/tenantConstant';

    export function loadTenantFinanceRequest(data) {
      return {
        type: LOAD_TENANT_FINANCE_REQUEST,
        payload: data,
      }
    };

    export function loadTenantBasicRequest(data) {
      return {
        type: LOAD_TENANT_BASIC_REQUEST,
        payload: data,
      }
    };
    
    export function loadTenantDetailsLoading(data) {
      return {
        type: LOAD_TENANT_DETAILS_LOADING,
        payload: data,
      }
    };
      
    export function loadTenantFinanceSuccess(data) {
      return {
        type: LOAD_TENANT_FINANCE_SUCCESS,
        payload: data,
      }
    };

    export function loadTenantBasicSuccess(data) {
      return {
        type: LOAD_TENANT_BASIC_SUCCESS,
        payload: data,
      }
    };
    
    export function loadTenantDetailsError(data) {
      return {
        type: LOAD_TENANT_DETAILS_ERROR,
        payload: data,
      }
    };

    export function sendTenantDetailsSuccess(data) {
        return {
          type: SEND_TENANT_DETAILS_SUCCESS,
          payload: data,
        }
      };

    export function sendTenantDetailsRequest(data) {
        return {
          type: SEND_UPDATE_TENANT_DETAILS_REQUEST,
          payload: data,
        }
      };
      
    export function loadTenantCorrespondenceRequest(data) {
        return {
          type: LOAD_TENANT_CORRESPONDENCE_REQUEST,
          payload: data,
        }
      };

    export function loadTenantCorrespondenceSuccess(data) {
        return {
          type: LOAD_TENANT_CORRESPONDENCE_SUCCESS,
          payload: data,
        }
      };
      
    export function loadTenantDefaultRequest(data) {
        return {
          type: LOAD_TENANT_DEFAULT_REQUEST,
          payload: data,
        }
      };

    export function loadTenantDefaultSuccess(data) {
        return {
          type: LOAD_TENANT_DEFAULT_SUCCESS,
          payload: data,
        }
      };

    export function loadTenantAccountSystemRequest(data) {
        return {
          type: LOAD_TENANT_ACCOUNT_SYSTEM_REQUEST,
          payload: data,
        }
      };

    export function loadTenantAccountSystemSuccess(data) {
        return {
          type: LOAD_TENANT_ACCOUNT_SYSTEM_SUCCESS,
          payload: data,
        }
      };
      
    export function loadTenantServicesAreaRequest() {
        return {
          type: LOAD_TENANT_SERVICE_AREA_REQUEST,
        }
      };
  
    export function loadTenantServicesAreaSuccess(data) {
        return {
          type: LOAD_TENANT_SERVICE_AREA_SUCCESS,
          payload: data,
        }
      };
      
    export function loadTenantServicesRequest() {
        return {
           type: LOAD_TENANT_SERVICE_REQUEST,
        }
      };
    
    export function loadTenantServicesSuccess(data) {
        return {
          type: LOAD_TENANT_SERVICE_SUCCESS,
          payload: data,
        }
      };

  