const PATH = 'app/Tenant';

export const LOAD_TENANT_BASIC_REQUEST = `${PATH}LOAD_TENANT_BASIC_REQUEST`;
export const LOAD_TENANT_BASIC_SUCCESS = `${PATH}LOAD_TENANT_BASIC_SUCCESS`;
export const LOAD_TENANT_FINANCE_REQUEST = `${PATH}LOAD_TENANT_FINANCE_REQUEST`;
export const LOAD_TENANT_FINANCE_SUCCESS = `${PATH}LOAD_TENANT_FINANCE_SUCCESS`;
export const LOAD_TENANT_CORRESPONDENCE_REQUEST = `${PATH}LOAD_TENANT_CORRESPONDENCE_REQUEST`;
export const LOAD_TENANT_CORRESPONDENCE_SUCCESS = `${PATH}LOAD_TENANT_CORRESPONDENCE_SUCCESS`;
export const LOAD_TENANT_DETAILS_ERROR = `${PATH}LOAD_TENANT_DETAILS_ERROR`;
export const LOAD_TENANT_DETAILS_LOADING = `${PATH}LOAD_TENANT_DETAILS_LOADING`;
export const SEND_TENANT_DETAILS_SUCCESS = `${PATH}SEND_TENANT_DETAILS_SUCCESS`;
export const SEND_UPDATE_TENANT_DETAILS_REQUEST = `${PATH}SEND_UPDATE_TENANT_DETAILS_REQUEST`;
export const LOAD_TENANT_DEFAULT_REQUEST = `${PATH}LOAD_TENANT_DEFAULT_REQUEST`;
export const LOAD_TENANT_DEFAULT_SUCCESS = `${PATH}LOAD_TENANT_DEFAULT_SUCCESS`;
export const LOAD_TENANT_ACCOUNT_SYSTEM_REQUEST = `${PATH}LOAD_TENANT_ACCOUNT_SYSTEM_REQUEST`;
export const LOAD_TENANT_ACCOUNT_SYSTEM_SUCCESS = `${PATH}LOAD_TENANT_ACCOUNT_SYSTEM_SUCCESS`;
export const LOAD_TENANT_SERVICE_REQUEST = `${PATH}LOAD_TENANT_SERVICE_REQUEST`;
export const LOAD_TENANT_SERVICE_SUCCESS = `${PATH}LOAD_TENANT_SERVICE_SUCCESS`;
export const LOAD_TENANT_SERVICE_AREA_REQUEST = `${PATH}LOAD_TENANT_SERVICE_AREA_REQUEST`;
export const LOAD_TENANT_SERVICE_AREA_SUCCESS = `${PATH}LOAD_TENANT_SERVICE_AREA_SUCCESS`;