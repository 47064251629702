import Cookies from 'js-cookie';

const common = 
    {
        userRole : [
            { id: 'admin', description: 'Admin User' },
            { id: 'general', description: 'General User' }],
        status : [
            { id: 0, description: 'Inactive' },
            { id: 1, description: 'Active' }
        ],
        discountType : [
            { id: '0', value:'0', description: 'Discount Percentage' },
            { id: '1', value:'1', description: 'Discount Value' }
        ],
        headers: {
            'Content-Type': 'application/json',
            'authorization' : sessionStorage.getItem("accessToken")
        },
        emailObjectType : [
            { id: 'BOOKING', description: 'Booking' },
            { id: 'VEHICLE', description: 'Service Reminder' },
            { id: 'REGISTRATION', description: 'Registration' },
            { id: 'SURVEY', description: 'Survey' },
            { id: 'PURCHASEORDER', description: 'Purchase Order' },
            { id: 'JOBCARD_IMAGE', description: 'Job Card Image' },
            { id: 'QUOTATION_IMAGE', description: 'Quotation Image' }
        ],
        maxLengths:{
            vehicleTypeDescription:50,
            makeName:40,
            modelName:60,
            partId:16,
            partDescription:40,
            partUnitPrice:10,
            partQuantity:10,
            repairCodeId:10,
            repairDescription:250,
            repairUnitPrice:10,
            repairQuantity:8,
            customerFirstName:50,
            customerLastName:30,
            customerEmail:60,
            customerMobile:20,
            customerPhone:20,
            customerMiddleName:30,
            customerFax:60,
            customerCustomField1:30,
            customerCustomField2:30,
            customerDisplayName:50,
            customerBusinessName:50,
            streetNo:10,
            streetName:25,
            cityName:30,
            postCode:10,
            region:15,
            vehicleRegNo:10,
            vehicleColor:30,
            vehicleTyreSize:20,
            vehicleCustomField:50,
            numberOfCylinders:11,
            lastServiceMileage:11,
            modelVariant:40,
            serviceDueMileage:11,
            vehicleIdentificationNo:50,
            engineNo:50,
            businessName:60,
            email:45,
            phone:12,
            mobile:12,
            licenseNo:30,
            fax:50,
            brn:15,
            jobCardTemplateName:50,
            userEmail:250,
            userFirstName:150,
            userLastName:150,
            userId:15,
            userMobile:50,
            quotaionEmail:100,
            titleName:20,
            currencyCode:3,
            currencySymbol:3,
            taxRate:10,
            bankAccountName:50,
            nameOfTheBank:40,
            bSB:6,
            account:15,
            serviceId:10,
            serviceSchedulesDescription:100,
            buildYear:4,
            estimatedEffort:8,
            supplierName:50,
            supplierContactName:50,
            supplierEmail:60,
            supplierPhone:20,
            supplierMobile:20,
            supplierFax:60,
            supplierCustomField1:30,
            supplierCustomField2:30,
            referenceNo:15,
            cost:10,
            productQuantity:10,
            productUnitPrice:10
        },
        objectId:{
            jobCard:'JOBCARD',
            invoice:'INVOICE',
            parts:'PARTS',
            repair:'REPAIR',
            customer:'CUSTOMER',
            vehicle:'VEHICLE',
            jobCardTemplate:'JOBCARD_TEMPLATE',
            user:'USER',
            booking:'BOOKING',
            serviceShedule: 'SERVICE_SCHEDULE',
            supplier:'SUPPLIER',
            purchasing:'PURCHASEORDER',
            products:'PRODUCTS'
        },
        userStatus:{
            active:"10",
            inActive:"50"
        },
        error:"Unexpected error occurred",
        serviceRemindersMessage : "Service reminders was schedule to sent",
        invoiceEmailMessage: "Email was sent",
        purchasingEmailMessage: "Email was sent",
        purchasingAddAndPrintError: "Record successfully created.Can't print !",
        purchasingAddAndEmailError: "Record successfully created.Can't send email !",
        dateFormat:"YYYY-MM-DD",
        dateTimeFormat:"YYYY-MM-DD HH:mm",
        timeFormat:'HH:mm',
        bookingRemindersMessage : "Booking reminders was schedule to sent",
        groupBy:[
            { id: 1, description: 'Invoice No' },
            { id: 2, description: 'Date' },
            { id: 3, description: 'Month' },
            { id: 4, description: 'Year' }
        ],
        massages:{
            emailMassage:"Please enter your email address",
            requiredFieldMassage:"Missing required Field",
            customerDeleteMassage:"This customer can't delete",
            supplierDeleteMassage:"This supplier can't delete",
        },
        deleteMassages : [
            { id: 6001, description: 'This Customer has Vehicles' },
            { id: 6002, description: 'This Customer has Invoice' },
            { id: 6003, description: 'This Customer has Quotation' },
            { id: 6004, description: 'This Customer has Jobcard' },
            { id: 6005, description: 'This Vehicle has Jobcard' },
            { id: 6006, description: 'This Vehicle has Invoice' },
            { id: 6007, description: 'This Vehicle has Quotation' },
            { id: 6009, description: 'This Jobcard has Quotation' },
            { id: 6010, description: 'This Jobcard has Invoice' },
        ],
        lineChartDetails :{
            labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun','Jul','Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            chartColor_1: '#ff6384',
            chartColor_2: '#36a2eb',
            chartColor_3: '#FFCD56',
            chartColor_4: '#2EFD4A',
            chartColor_5: '#ec0032',
            viewOption:[
                { id: 1, description: 'Last 3 Years' },
                { id: 2, description: 'Last 5 Years' },
            ]
        },
        pieChartDetails : {
            labels: ['5 Star', '4 Star', '3 Star','2 Star', '1 Star'],
            backgroundColor: [
                '#2EFD4A',
                '#36a2eb',
                '#ffff00',
                '#f5a423',
                '#ec0032'
            ],
        },
        graphicalCalender:{
            confirmedColor:'#36a2eb',
            unConfirmColor: '#ff6384',
            monthView:'month',
            weekView:'week',
            dayView:'day'
        },
        tenantDefault:{
            model:"COMMON_MODEL",
        },
    }


export default common

export function refeshTokenset(accessToken) {
    // console.log('refeshTokenset')
    if(accessToken === null){
        return
    }else
        sessionStorage.setItem('accessToken', accessToken);
}

export function invalidToken(){
    // console.log('invalidToken')
    Cookies.remove("user")
    sessionStorage.removeItem('accessToken')
    localStorage.removeItem('user')
    window.location.reload('/');
}

export function numberWithCommas(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function genarateVehicleAddObject(selectedVehicle, id) {
    const parameters = {
        isExistVehicle: selectedVehicle.isExistVehicle,
        id: '',
        vehicleTypeId: '',
        vehicleRegistrationNumber: selectedVehicle.vehicleRegistrationNumber ,
        complianceDate:'',
        builtDate:'',
        lastServicedDate: '',
        serviceDueDate: '',
        serviceDueMillage: '',
        customerId: id,
        colour: '',
        vehicleIdentificationNo: '',
        makeId: selectedVehicle.makeId,
        modelId: selectedVehicle.modelId,
        modelVariance: '',
        statusId: selectedVehicle.statusId,
        engineNo: '',
        transmissionTypeId: '',
        fuelTypeId: '',
        tyreSize: '',
        customerField1: '',
        customerField2: '',
        customerField3: '',
        serviceRemindersSent: '',
        registrationDueDate:'',
        numberOfCylinders: '',
        lastServicedMillage: '',
        notes: '',
        tenantId: selectedVehicle.tenantId
    }
    return parameters;
}