import React from 'react';
import PropTypes from 'prop-types';
import './pages.css';
import TextField from '../layout/form/TextField';
import common from '../common';
import Button from '../layout/form/Button';

const Name = ({
    handleChange,
    data,
    backOnClick,
    nextOnClick,
    isInvalid,
    businessName
}) => (
    <div className='content name'>
        <div className='header-titile'>
            {businessName ? businessName.toUpperCase() : null}
        </div>
        <div className='start-content'>
            Customer Check In
        </div>
        <div className="row">
            <div className="col-sm">
                <TextField
                    text="First Name"
                    controlId="firstName"
                    type="text"
                    onChange={handleChange}
                    defaultValue={data.firstName}
                    maxLength={common.maxLengths.customerFirstName}
                />
            </div>
        </div>
        <div className="row">
            <div className="col-sm">
                <TextField
                    text="Last Name"
                    controlId="lastName"
                    type="text"
                    onChange={handleChange}
                    defaultValue={data.lastName}
                    maxLength={common.maxLengths.customerLastName}
                />
            </div>
        </div>
        <div className="row">
            <div className="col-sm">
                <TextField
                    text="Company Name"
                    controlId="companyName"
                    type="text"
                    onChange={handleChange}
                    defaultValue={data.companyName}
                    maxLength={common.maxLengths.customerBusinessName}
                />
            </div>
        </div>
        <div className="row">
            <div className="col-sm">
                <TextField
                    text="Display Name"
                    controlId="displayName"
                    type="text"
                    onChange={handleChange}
                    defaultValue={data.displayName}
                    maxLength={common.maxLengths.customerDisplayName}
                    isInvalid={isInvalid}
                />
            </div>
        </div>
        <div className='footer-button'>
            <Button
                text="Back"
                variant="outline-primary"
                onClick={backOnClick}
            />
            <Button
                text="Next"
                variant="outline-primary"
                onClick={nextOnClick}
                disabled={
                    data.displayName === '' || data.displayName === null ||
                    data.firstName === '' || data.firstName === null ||
                    data.lastName === '' || data.lastName === null
                }
            />
        </div>
        <div className='step-bar' style={{ marginTop: '-150px' }}>
            <div className='step-bar-title'>Name...</div>
            <div className='dot-bar'>
                <div className='prev-dot'></div>
                <div className='prev-dot'></div>
                <div className='active-dot'></div>
                <div className='next-dot'></div>
                <div className='next-dot'></div>
            </div>
        </div>
    </div>
);

Name.propTypes = {
    handleChange: PropTypes.any,
    data: PropTypes.any,
}

export default Name;