import { fromJS } from 'immutable';
import {
  LOAD_COUNTRY_SUCCESS,
  LOAD_OBJECT_STATUS_SUCCESS,
  LOAD_TENANT_SUCCESS,
  LOAD_TENANT_USER_SUCCESS,
  LOAD_UOM_SUCCESS,
} from '../constant/constant';

export const initialState = fromJS({
    objectStatusList:[],
    uomList:[],
    countryList:[],
    tenant:{},
    tenantUser:{}
});

function appReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_OBJECT_STATUS_SUCCESS:
      return state.set('objectStatusList', action.payload)
    case LOAD_UOM_SUCCESS:
      return state.set('uomList', action.payload)
    case LOAD_COUNTRY_SUCCESS:
      return state.set('countryList', action.payload)
    case LOAD_TENANT_SUCCESS:
      return state.set('tenant', action.payload)
    case LOAD_TENANT_USER_SUCCESS:
      return state.set('tenantUser', action.payload)
    default:
      return state;
  }
}

export default appReducer;
