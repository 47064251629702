import React from 'react';
import PropTypes from 'prop-types';
import "@ui5/webcomponents-icons/dist/accept";
import './pages.css';
import Button from '../layout/form/Button';
import VemasLogo from '../../images/user.png';

// function openInNewTab(url) {
//     const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
//     if (newWindow) newWindow.opener = null
// }


const Start = ({
    onClick,
    buttonText,
    tenantDetails
}) => (
    <div>
        <div className='header'>
            <div className='start-welcome'>
                <h4>Welcome!</h4>
            </div>
        </div>

        <div className='header-logo'>
            <img alt="tenant_logo" id="tenant_logo" src={tenantDetails && tenantDetails.logo ? tenantDetails.logo : VemasLogo} />
        </div>
        <div className='header-titile'>
           {tenantDetails && tenantDetails.businessName ? tenantDetails.businessName.toUpperCase() : null }
        </div>
        <div className='start-content'>
            Customer Check In
        </div>
        <div className='start'>
            <Button
                onClick={onClick}
                text={buttonText}
                variant="outline-primary"
                width="250px"
                borderRadius="50px"
            />
        </div>
    </div>
);

Start.propTypes = {
    onClick: PropTypes.any,
}

export default Start;