import axios from 'axios'

const baseURL = process.env.REACT_APP_API_URL
const apiKey = process.env.REACT_APP_API_KEY
const xApiKey = process.env.REACT_APP_X_API_KEY

export function addNewVehicleRequest(data){
    const parameters = JSON.stringify(data)
    //console.log(parameters)
      return axios.post(baseURL+'vehicle?TenantId='+data.tenantId, parameters, {
          headers: {
              'Content-Type': 'application/json',
              'Authorization' : apiKey,
              'x-api-key': xApiKey
          }
      })
}

export function getVehicleList(parameters){
    return axios.get(baseURL+'vehicle?pageSize='+parameters.pageSize+'&pageNumber='+parameters.pageNumber+'&CustomerId='+parameters.Customer+'&MakeId='+parameters.Make+'&ModelId='+parameters.Model+'&RegNo='+parameters.Search+'&StatusId='+parameters.Status+'&commonSearch='+parameters.commonSearch+'&TenantId='+parameters.tenantId, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : apiKey,
            'x-api-key': xApiKey
        }
    })
}

export function updateVehicleRequest(data){
    const parameters = JSON.stringify(data)
    //console.log(parameters)
    return axios.patch(baseURL+'vehicle/'+data.id+'?TenantId='+data.tenantId, parameters, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : apiKey,
            'x-api-key': xApiKey
        }
    })
}

export function getSelectedVehicleRequest(parameters){
    return axios.get(baseURL+'vehicle/'+parameters.vehicleId+'?TenantId='+parameters.tenantId, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : apiKey,
            'x-api-key': xApiKey
        }
    })
}

export function getMakeList(parameters){ 
    return axios.get(baseURL+'make?pageSize='+parameters.pageSize+'&pageNumber='+parameters.pageNumber+'&name='+parameters.name+'&TenantId='+parameters.tenantId, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : apiKey,
            'x-api-key': xApiKey
        }
    })
}

export function getModelList(parameters){
    return axios.get(baseURL+'model?pageSize='+parameters.pageSize+'&pageNumber='+parameters.pageNumber+'&Name='+parameters.name+'&MakeId='+parameters.makeId+'&TenantId='+parameters.tenantId, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : apiKey,
            'x-api-key': xApiKey
        }
    })
}
