import React from 'react';
import PropTypes from 'prop-types';
import "@ui5/webcomponents-icons/dist/thumb-up";
import "@ui5/webcomponents/dist/Icon";
import "@ui5/webcomponents-icons/dist/Assets";
import './pages.css';
import ThumbUp from '../../images/hand-thumbs-up.png';
import VemasLogo from '../../images/user.png';

const Success = ({
    tenantDetails
}) => (
    <div>
        <div className='success-content'>
            <div >
                <img alt="thumb-up" id="thumb-up" src={ThumbUp} />
            </div>
            <h3>Thank You!</h3>
            <h5>You have successfully checked in</h5>
        </div>
        <div className='success-logo'>
            <img alt="tenant_logo" id="tenant_logo" src={tenantDetails && tenantDetails.logo ? tenantDetails.logo : VemasLogo} />
        </div>
        <div className='success-titile'>
            <div>
                {tenantDetails && tenantDetails.businessName ? tenantDetails.businessName.toUpperCase() : null}
            </div>
            <small>
                {tenantDetails && tenantDetails.billStreetNumber ? tenantDetails.billStreetNumber + ", " : null} {tenantDetails && tenantDetails.billStreetName ? tenantDetails.billStreetName +", " : null} {tenantDetails && tenantDetails.billCity}
            </small>
            <br/>
            <small>
                {tenantDetails && tenantDetails.billRegion ? tenantDetails && tenantDetails.billRegion +", " : null} {tenantDetails && tenantDetails.billCountry && tenantDetails.billCountry.name}
            </small>
            <br/>
            <small>
                {tenantDetails && tenantDetails.phoneNumber ? tenantDetails && tenantDetails.phoneNumber  : null} 
            </small>
            <br/>
            <small>
                {tenantDetails && tenantDetails.email ? tenantDetails && tenantDetails.email +", " : null} 
            </small>
        </div>
    </div>
);

Success.propTypes = {
    onClick: PropTypes.any,
}

export default Success;