import { createSelector } from 'reselect';

const selectCustomerDomain = state => state.customer;

const makeSelectCustomerPopupOpen =() =>
  createSelector(
    selectCustomerDomain,
    customerState => 
      customerState ? customerState.get('isAddNewCustomerModalOpen') : false
  );

const makeSelectCustomerList = () =>
  createSelector(
    selectCustomerDomain,
    customerState => 
      customerState ? customerState.get('customerList') : []
  );

const makeSelectCustomerListLoading = () =>
  createSelector(
    selectCustomerDomain,
    customerState => 
      customerState ? customerState.get('isCustomerListLoading') : false
  );

const makeSelectCustomerListError = () =>
  createSelector(
    selectCustomerDomain,
    customerState => 
      customerState ? customerState.get('customerListError') : null
  );

const makeSelectCustomerSuccess = () =>
  createSelector(
    selectCustomerDomain,
    customerState => 
      customerState ? customerState.get('customerSuccess') : null
  );

const makeSelectSelectedCustomerSuccess = () =>
  createSelector(
    selectCustomerDomain,
    customerState => 
    customerState ? customerState.get('selectedCustomer') : ''
  );

export {
    makeSelectCustomerPopupOpen,
    makeSelectCustomerList,
    makeSelectCustomerListLoading,
    makeSelectCustomerListError,
    makeSelectCustomerSuccess,
    makeSelectSelectedCustomerSuccess
}
