import { createSelector } from 'reselect';

const selectTenantDomain = state => state.tenant;

const makeSelectTenantBasic = () =>
  createSelector(
    selectTenantDomain,
    tenantDetailsState => 
      tenantDetailsState ? tenantDetailsState.get('tenantDetails') : {}
  );


const makeSelectTenantFinance = () =>
  createSelector(
    selectTenantDomain,
    tenantDetailsState => 
      tenantDetailsState ? tenantDetailsState.get('tenantFinance') : {}
  );

const makeSelectTenantLoading = () =>
  createSelector(
    selectTenantDomain,
    tenantDetailsState => 
      tenantDetailsState ? tenantDetailsState.get('isTenantDetailsLoading') : false
  );

const makeSelectTenantError = () =>
  createSelector(
    selectTenantDomain,
    tenantDetailsState => 
      tenantDetailsState ? tenantDetailsState.get('tenantDetailsError') : ''
  );

const makeSelectTenantUpdateSuccess = () =>
  createSelector(
    selectTenantDomain,
    tenantDetailsState => 
      tenantDetailsState ? tenantDetailsState.get('tenantDetailsSuccess') : ''
  );
  
const makeSelectTenantCorrespondenceSuccess = () =>
  createSelector(
    selectTenantDomain,
    tenantDetailsState => 
      tenantDetailsState ? tenantDetailsState.get('tenantCorrespondence') : {}
  );

const makeSelectTenantDefaultSuccess = () =>
  createSelector(
    selectTenantDomain,
    tenantDetailsState => 
      tenantDetailsState ? tenantDetailsState.get('tenantDefault') : []
  );
  
  const makeSelectTenantAccountSystemSuccess = () =>
  createSelector(
    selectTenantDomain,
    tenantDetailsState => 
      tenantDetailsState ? tenantDetailsState.get('tenantAccountSystem') : ''
  );
  
  const makeSelectTenantServicesSuccess = () =>
    createSelector(
      selectTenantDomain,
      tenantDetailsState => 
        tenantDetailsState ? tenantDetailsState.get('tenantServices') : []
    );
  
  const makeSelectTenantServiceAreasSuccess = () =>
  createSelector(
    selectTenantDomain,
    tenantDetailsState => 
      tenantDetailsState ? tenantDetailsState.get('tenantAreas') : []
  );

export {
    makeSelectTenantBasic,
    makeSelectTenantFinance,
    makeSelectTenantLoading,
    makeSelectTenantError,
    makeSelectTenantUpdateSuccess,
    makeSelectTenantCorrespondenceSuccess,
    makeSelectTenantDefaultSuccess,
    makeSelectTenantAccountSystemSuccess,
    makeSelectTenantServicesSuccess,
    makeSelectTenantServiceAreasSuccess
}
