import { takeLatest, put, call } from 'redux-saga/effects';
// import * as dashboard from './dashboardSaga';
import * as tenant from './tenantSaga';
import * as customer from './customerSaga';
import * as vehicle from './vehicleSaga';
// import { 
//   LOAD_DASHBOARD_REQUEST, 
//   LOAD_PURCHASING_SUMMARY_REQUEST, 
//   LOAD_RATING_SUMMARY_REQUEST, 
//   LOAD_SALES_SUMMARY_REQUEST 
// } from '../../store/constant/dashboardConstant';
import { 
  loadObjectStatusSuccess, 
  loadTenantSuccess, 
  loadTenantUserSuccess
} from '../../store/action/action';
import { invalidToken, refeshTokenset } from '../common';
import { saveLoginUser } from '../../store/action/authAction';
import {
  getObjectStatusList,
  getTenantCommon,
} from '../service/service';
import { getSelectedUserRequest } from '../service/usersService';
import { LOAD_TENANT_BASIC_REQUEST } from '../../store/constant/tenantConstant';
import { LOAD_CUSTOMER_LIST_REQUEST, LOAD_SELECTED_CUSTOMER_REQUEST, SEND_ADD_NEW_CUSTOMER_REQUEST, SEND_UPDATE_CUSTOMER_REQUEST } from '../../store/constant/customerConstant';
import { LOAD_MAKE_LIST_REQUEST, LOAD_MODEL_LIST_REQUEST, LOAD_SELECTED_VEHICLE_REQUEST, LOAD_VEHICLE_LIST_REQUEST, SEND_ADD_NEW_VEHICLE_REQUEST, SEND_UPDATE_VEHICLE_REQUEST } from '../../store/constant/vehicleConstant';


export function* loadObjectStatusRequest(data){
  try{
    const response = yield call(getObjectStatusList,data.payload);
    if(response && response.data && response.data.statusCode && response.data.statusCode === 200 ){
      yield put(loadObjectStatusSuccess(response.data.result.result))
    } 
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    }  
    if(response && response.data && response.data.result && response.data.result.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
  }catch(error){
  }
}

export function* loadTenantCommonRequest(data){
  try{
    const response = yield call(getTenantCommon,data.payload);
    if(response && response.status && response.status === 200 ){
      sessionStorage.setItem('dateFormat', response.data.dateFormat);
      const tenant = response.data
      yield put(loadTenantSuccess(tenant))
    }  
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    } 
    if(response && response.data  && response.data.newAccessToken){
      refeshTokenset(response.data.newAccessToken)
    } 
  }catch(error){
  }
}

export function* loadTenantUserRequest(data){
  try{
    const response = yield call(getSelectedUserRequest,data.payload);
    if(response && response.status && response.status === 200 ){
      const User = response.data
      yield put(loadTenantUserSuccess(User))
    } 
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    } 
    if(response && response.data && response.data.newAccessToken){
      refeshTokenset(response.data.newAccessToken)
    }
  }catch(error){
  }
}

export default function* rootSaga(){
  yield takeLatest(LOAD_TENANT_BASIC_REQUEST, tenant.loadTenantBasicRequest);
  yield takeLatest(LOAD_CUSTOMER_LIST_REQUEST, customer.loadCustomerListRequest);
  yield takeLatest(LOAD_SELECTED_CUSTOMER_REQUEST, customer.loadSelectedCustomerRequest);
  yield takeLatest(SEND_ADD_NEW_CUSTOMER_REQUEST, customer.sendAddNewCustomerRequest);
  yield takeLatest(SEND_UPDATE_CUSTOMER_REQUEST, customer.sendUpdateCustomerRequest);
  yield takeLatest(LOAD_VEHICLE_LIST_REQUEST, vehicle.loadVehicleListRequest);
  yield takeLatest(LOAD_MAKE_LIST_REQUEST, vehicle.loadMakeListRequest);
  yield takeLatest(LOAD_MODEL_LIST_REQUEST, vehicle.loadModelListRequest);
  yield takeLatest(LOAD_SELECTED_VEHICLE_REQUEST, vehicle.loadSelectedVehicleRequest);
  yield takeLatest(SEND_ADD_NEW_VEHICLE_REQUEST, vehicle.sendAddNewVehicleRequest);
  yield takeLatest(SEND_UPDATE_VEHICLE_REQUEST, vehicle.sendUpdateVehicleRequest);
  // yield takeLatest(LOAD_DASHBOARD_REQUEST, dashboard.loadDashboardDetailsRequest);
  // yield takeLatest(LOAD_SALES_SUMMARY_REQUEST, dashboard.loadSalesSummaryRequest);
  // yield takeLatest(LOAD_RATING_SUMMARY_REQUEST, dashboard.loadRatingSummaryRequest);
  // yield takeLatest(LOAD_PURCHASING_SUMMARY_REQUEST, dashboard.loadPurchaseOrderSummaryRequest);
}