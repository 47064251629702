import React from 'react';
import BootstrapFormLabel from 'react-bootstrap/cjs/FormLabel';
import BootstrapFormGroup from 'react-bootstrap/cjs/FormGroup';
import 'bootstrap/dist/css/bootstrap.min.css';
import PropTypes from 'prop-types';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

const PhoneNumberField = ({
    controlId,
    text,
    type,
    placeholder,
    as,
    row,
    onChange,
    options,
    display,
    required,
    hint,
    defaultHint,
    ...props
}) => (
    <div style={{ marginTop: '5px', padding: '0 5px', fontSize: '20px', color: '#262262' }}>
        <BootstrapFormGroup controlId={controlId} >
            <BootstrapFormLabel style={{ fontWeight: 500, marginBottom: '5px' }}>
                {text} {required ? <span style={{ fontSize: '11px', color: '#FF5733' }}>* {hint}</span> : defaultHint ? <span style={{ fontSize: '11px', color: '#d5d7dc' }}>( {defaultHint} )</span> : ''}
                {/* {defaultHint ? : ''} */}
            </BootstrapFormLabel>
            <PhoneInput
                // placeholder="Enter phone number"
                {...props}
                onChange={onChange}
                // country="AUS"
                fullWidth="true"
                defaultCountry="AU"
                className={"input-phone-number"}
                dropdownStyle={{height:'50px'}}
            />
        </BootstrapFormGroup>
    </div>
);

PhoneNumberField.propTypes = {
    controlId: PropTypes.any,
    text: PropTypes.string,
    type: PropTypes.any,
    placeholder: PropTypes.any,
    as: PropTypes.any,
    row: PropTypes.any,
    onChange: PropTypes.any,
    options: PropTypes.any,
    display: PropTypes.any,
    required: PropTypes.any,
    hint: PropTypes.any,
    defaultHint: PropTypes.any,
}

export default PhoneNumberField;