import { put, call } from 'redux-saga/effects';
import {
  addNewVehicleRequest,
  getVehicleList,
  updateVehicleRequest,
  getMakeList,
  getModelList,
  getSelectedVehicleRequest,
  // getSelectedVehicleRequest
} from '../service/vehicleService';
import * as actions from '../../store/action/vehicleAction';
import { loadCustomerListError, loadSelectedCustomerRequest, sendCustomerSuccess } from '../../store/action/customerAction';
import common, { refeshTokenset } from '../common';
// import { saveLoginUser } from '../../store/action/authAction';


export function* sendAddNewVehicleRequest(data){
  //console.log('sendAddNewVehicleRequest')
  // yield put(actions.loadVehicleListError(''))
  try{
    // yield put(actions.loadVehicleListLoading(true));
    const response = yield call(addNewVehicleRequest,data.payload);
    if(response && response.data && response.data.statusCode && response.data.statusCode === 201 ){
      //console.log(response.data.message)
      const massage = response.data.message
      yield put(sendCustomerSuccess(massage))
    }
    // else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
    //   yield put(saveLoginUser(false))
    //   invalidToken()
    // } 
    else {
      const massage = response.data.message
      yield put(loadCustomerListError(massage))
    }
    if(response && response.data && response.data.result && response.data.result.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
    // yield put(actions.sendAddNewVehicleLoading(false));
  }catch(error){
    // yield put(actions.sendAddNewVehicleLoading(false));
    //console.log(error.response.data.detail)
    const massage  =common.error
    yield put(loadCustomerListError(massage))
  }
}

export function* loadVehicleListRequest(data){
  //console.log('loadVehicleListRequest')
  // yield put(actions.loadVehicleListError(''))
  try{
    // yield put(actions.loadVehicleListLoading(true));
    const response = yield call(getVehicleList,data.payload);
    if(response && response.data && response.data.statusCode && response.data.statusCode === 200 ){
      //console.log(response.data.result.result)
      const vehicleList = response.data.result.result
      if(vehicleList  && vehicleList.length > 0){
        const vehicle = vehicleList[0]
        const parameters= {
          pageSize:9999,
          pageNumber:'',
          name:'',
          makeId:vehicle.makeId,
          tenantId: data.payload.tenantId,
          customerId: vehicle.customerId,
          vehicleId: vehicle.id
        }
        yield put(actions.loadSelectedVehicleRequest(parameters))
        yield put(loadSelectedCustomerRequest(parameters))
        yield put(actions.loadModelListRequest(parameters))
      }
      yield put(actions.loadVehicleListSuccess(vehicleList))
    }
    // else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
    //   yield put(saveLoginUser(false))
    //   invalidToken()
    // } 
    else{
      // yield put(actions.loadVehicleListLoading(false));
      const massage  =common.error
      yield put(loadCustomerListError(massage))
    }
    if(response && response.data && response.data.result && response.data.result.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
    // yield put(actions.loadVehicleListLoading(false));
  }catch(error){
    // yield put(actions.loadVehicleListLoading(false));
    //console.log(error)
    const massage  =common.error
    yield put(loadCustomerListError(massage))
  }
}

export function* sendUpdateVehicleRequest(data){
  //console.log('sendUpdateVehicleRequest')
  // yield put(actions.loadVehicleListError(''))
  try{
    // yield put(actions.sendUpdateVehicleLoading(true));
    const response = yield call(updateVehicleRequest,data.payload);
    if(response && response.data && response.data.statusCode && response.data.statusCode === 200 ){
      //console.log(response.data.result)
      const massage = response.data.message
      yield put(sendCustomerSuccess(massage))
    }
    // else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
    //   yield put(saveLoginUser(false))
    //   invalidToken()
    // } 
    else{
      const massage = response.data.message
      yield put(loadCustomerListError(massage))
    }
    if(response && response.data && response.data.result && response.data.result.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
    // yield put(actions.sendUpdateVehicleLoading(false));
  }catch(error){
    // yield put(actions.sendUpdateVehicleLoading(false));
    //console.log(error.response)
    const massage  =common.error
    yield put(loadCustomerListError(massage))
  }
}


export function* loadMakeListRequest(data){
  // console.log('loadMakeListRequest')
  // yield put(actions.loadMakeListError(''))
  try{
    // yield put(actions.loadMakeListLoading(true));
    const response = yield call(getMakeList,data.payload);
    if(response && response.data && response.data.statusCode && response.data.statusCode === 200 ){
      // console.log(response.data.result.result)
      // if(response.data.result.newAccessToken){
      //   refeshTokenset(response.data.result.newAccessToken)
      // }
      const makeList = response.data.result
      yield put(actions.loadMakeListSuccess(makeList))
    }
    // else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
    //   yield put(saveLoginUser(false))
    //   invalidToken()
    // } 
    else{
      // yield put(actions.loadMakeListLoading(false));
      const massage  =common.error
      yield put(loadCustomerListError(massage))
    }
    if(response && response.data && response.data.result && response.data.result.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
    // yield put(actions.loadMakeListLoading(false));
  }catch(error){
    // yield put(actions.loadMakeListLoading(false));
    // console.log(error)
    const massage  =common.error
    yield put(loadCustomerListError(massage))
  }
}

export function* loadModelListRequest(data){
  // console.log('loadModelListRequest')
  // yield put(actions.loadModelListError(''))
  try{
    // yield put(actions.loadModelListLoading(true));
    const response = yield call(getModelList,data.payload);
    if(response && response.data && response.data.statusCode && response.data.statusCode === 200 ){
      // console.log(response.data.result.result)
      const modelList = response.data.result
      yield put(actions.loadModelListSuccess(modelList))
    }
    // else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
    //   yield put(saveLoginUser(false))
    //   invalidToken()
    // } 
    else{
      // yield put(actions.loadModelListLoading(false));
      const massage  =common.error
      yield put(loadCustomerListError(massage))
    }
    if(response && response.data && response.data.result && response.data.result.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
    // yield put(actions.loadModelListLoading(false));
  }catch(error){
    // yield put(actions.loadModelListLoading(false));
    // console.log(error)
    const massage  =common.error
    yield put(loadCustomerListError(massage))
  }
}

export function* loadSelectedVehicleRequest(data){
  //console.log('loadSelectedVehicleRequest')
  // yield put(actions.loadVehicleListError(''))
  try{
    // yield put(actions.loadVehicleListLoading(true));
    const response = yield call(getSelectedVehicleRequest,data.payload);
    if(response && response.status && response.status === 200 ){
      //console.log(response.data)
      const vehicle = response.data
      yield put(actions.loadSelectedVehicleSuccess(vehicle))
    }
    // else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
    //   yield put(saveLoginUser(false))
    //   invalidToken()
    // } 
    else{
      // yield put(actions.loadVehicleListLoading(false));
      const massage  =common.error
      yield put(loadCustomerListError(massage))
    }
    if(response && response.data && response.data.newAccessToken){
      refeshTokenset(response.data.newAccessToken)
    }
    // yield put(actions.loadVehicleListLoading(false));
  }catch(error){
    // yield put(actions.loadVehicleListLoading(false));
    //console.log(error)
    const massage  =common.error
    yield put(loadCustomerListError(massage))
  }
}
