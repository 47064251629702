import axios from 'axios'
// import common from '../common'

const baseURL = process.env.REACT_APP_API_URL
const apiKey = process.env.REACT_APP_API_KEY
const xApiKey = process.env.REACT_APP_X_API_KEY

export function getTenantFinance(id){
    return axios.get(baseURL+'tenants/finance/'+id,{
        headers: {
            'Content-Type': 'application/json',
            'authorization' : sessionStorage.getItem("accessToken")
        }
    })
}

export function getTenantBasic(id){
    return axios.get(baseURL+'tenant/'+id,{
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : apiKey,
            'X-Api-Key': xApiKey
        }
    })
  };

export function getTenantCorrespondence(id){
    return axios.get(baseURL+'tenants/correspondance/'+id,{
        headers: {
            'Content-Type': 'application/json',
            'authorization' : sessionStorage.getItem("accessToken")
        }
    })
  };

export function updateTenantDetails(data){
    const parameters = JSON.stringify(data)
    // console.log(parameters)
    return axios.patch(baseURL+'tenants/'+data.id, parameters, {
        headers: {
            'Content-Type': 'application/json',
            'authorization' : sessionStorage.getItem("accessToken")
        }
    })
}

export function getTenantDefault(id){
    return axios.get(baseURL+'tenants/default/'+id, {
        headers: {
            'Content-Type': 'application/json',
            'authorization' : sessionStorage.getItem("accessToken")
        }
    })
}

export function getTenantAccountSystem(id){
    return axios.get(baseURL+'tenants/accounting/'+id, {
        headers: {
            'Content-Type': 'application/json',
            'authorization' : sessionStorage.getItem("accessToken")
        }
    })
}

export function getTenantServices(){
    return axios.get(baseURL+'tenants/services',{
        headers: {
            'Content-Type': 'application/json',
            'authorization' : sessionStorage.getItem("accessToken")
        }
    })
  };

export function getTenantServiceArea(){
    return axios.get(baseURL+'tenants/serviceAreas',{
        headers: {
            'Content-Type': 'application/json',
            'authorization' : sessionStorage.getItem("accessToken")
        }
    })
  };