import axios from 'axios'
// import common from '../common'

const baseURL = process.env.REACT_APP_API_URL
const apiKey = process.env.REACT_APP_API_KEY
const xApiKey = process.env.REACT_APP_X_API_KEY

export function addNewCustomerRequest(data){
    const parameters = JSON.stringify(data)
    // console.log(parameters)
      return axios.post(baseURL+'customer?TenantId='+data.tenantId, parameters, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : apiKey,
            'X-Api-Key': xApiKey
        }
      })
}

export function getCustomerList(parameters){
    return axios.get(baseURL+'customer?pageSize='+parameters.pageSize+'&pageNumber='+parameters.pageNumber+'&name='+parameters.name+'&city='+parameters.city+'&email='+parameters.email+'&mobile='+parameters.mobile+'&commonSearch='+parameters.commonSearch+'&TenantId='+parameters.tenantId, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : apiKey,
            'X-Api-Key': xApiKey
        }
    })
}

export function updateCustomerRequest(data){
    const parameters = JSON.stringify(data)
    //console.log(parameters)
    return axios.patch(baseURL+'customer/'+data.id+'?TenantId='+data.tenantId, parameters, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : apiKey,
            'X-Api-Key': xApiKey
        }
    })
}

export function deleteCustomerRequest(id){
    return axios.delete(baseURL+'customers/'+id, {
        headers: {
            'Content-Type': 'application/json',
            'authorization' : sessionStorage.getItem("accessToken")
        }
    })
}

export function getSelectedCustomerRequest(parameters){
    return axios.get(baseURL+'customer/'+parameters.customerId+'?TenantId='+parameters.tenantId, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : apiKey,
            'X-Api-Key': xApiKey
        }
    })
}