import { combineReducers } from 'redux'
import customerReducer from './customerReducer'
// import titleReducer from './titleReducer'
import authReducer from './authReducer'
import appReducer from './appReducer'
import tenantReducer from './tenantReducer'
import vehicleReducer from './vehicleReducer'

const rootReducer = combineReducers({
    app : appReducer,
    customer: customerReducer,
    vehicle: vehicleReducer,
    tenant: tenantReducer,
    auth: authReducer
});

export default rootReducer