import React from 'react';
import PropTypes from 'prop-types';
import './pages.css';
import TextField from '../layout/form/TextField';
import common from '../common';
import Button from '../layout/form/Button';
import SelectField from '../layout/form/SelectField';

const Vehicle = ({
    handleChange,
    vehicleRegNo,
    backOnClick,
    nextOnClick,
    isInvalid,
    completedVehicleDetails,
    nextOnClickSecond,
    backOnClickSecond,
    makeList,
    handleChangeMake,
    make,
    handleChangeModel,
    modelList,
    model,
    businessName
}) => (
    <div className='content email'>
        <div className='header-titile'>
            {businessName ? businessName.toUpperCase() : null}
        </div>
        <div className='start-content'>
            Customer Check In
        </div>
        {completedVehicleDetails ?
            <div>
                <div className='row'>
                    <div className='col-sm'>
                        <SelectField
                            text="Make"
                            controlId="make"
                            onChange={handleChangeMake}
                            options={makeList && makeList.result ? makeList.result : []}
                            selectedValue={make}
                            isClearable
                        />
                        {/* <span className='text-feld-hint'>* Enter the Vehicle Brand</span> */}
                    </div>
                </div>
                <div className='row'>
                    <div className='col-sm'>
                        <SelectField
                            text="Model"
                            controlId="model"
                            onChange={handleChangeModel}
                            options={modelList && modelList.result ? modelList.result : []}
                            selectedValue={model}
                            isClearable
                        />
                        {/* <span className='text-feld-hint'>* Enter the Vehicle Model</span> */}
                    </div>
                </div>
                <div className='footer-button'>
                    <Button
                        text="Back"
                        variant="outline-primary"
                        onClick={backOnClickSecond}
                    />
                    <Button
                        text="Next"
                        variant="outline-primary"
                        onClick={nextOnClickSecond}
                        disabled={make === '' || make === null || model === '' || model === null}
                    />
                </div>
            </div> :
            <div>
                <div className='row'>
                    <div className='col-sm'>
                        <TextField
                            text="Vehicle registration number"
                            controlId="vehicleRegNo"
                            // placeholder="Email Address"
                            type="text"
                            onChange={handleChange}
                            defaultValue={vehicleRegNo}
                            maxLength={common.maxLengths.vehicleRegNo}
                            isInvalid={isInvalid}
                        />
                        {/* <span className='text-feld-hint'>* Enter Your Vehicle Number</span> */}
                    </div>
                </div>
                <div className='footer-button'>
                    <Button
                        text="Back"
                        variant="outline-primary"
                        onClick={backOnClick}
                    />
                    <Button
                        text="Next"
                        variant="outline-primary"
                        onClick={nextOnClick}
                        disabled={vehicleRegNo === '' || vehicleRegNo === null}
                    />
                </div>
            </div>}
        <div className='step-bar'>
            <div className='step-bar-title'>Vehicle...</div>
            <div className='dot-bar'>
                <div className='active-dot'></div>
                <div className='next-dot'></div>
                <div className='next-dot'></div>
                <div className='next-dot'></div>
                <div className='next-dot'></div>
            </div>
        </div>
    </div>
);

Vehicle.propTypes = {
    handleChange: PropTypes.any,
    email: PropTypes.any,
}

export default Vehicle;