import React from 'react';
import BootstrapFormControl from 'react-bootstrap/cjs/FormControl';
import BootstrapFormLabel from 'react-bootstrap/cjs/FormLabel';
import BootstrapFormGroup from 'react-bootstrap/cjs/FormGroup';
import 'bootstrap/dist/css/bootstrap.min.css';
import PropTypes from 'prop-types';

const TextField = ({
    controlId,
    text,
    type,
    placeholder,
    as,
    row,
    onChange,
    options,
    display,
    required,
    isInvalid,
    ...props
}) => (
    <div style={{ fontSize: '20px', color: '#262262', fontWeight: 500, marginBottom: 0
     }}>
        <BootstrapFormGroup controlId={controlId} >
            <BootstrapFormLabel style={{ fontWeight: 500 }}>
                {text}{isInvalid ? <small style={{ color: '#f80000' }}> * {text} is a required</small> : ''}
            </BootstrapFormLabel>
            <BootstrapFormControl
                {...props}
                type={type}
                placeholder={placeholder}
                as={as}
                row={row}
                onChange={onChange}
                size="lg"
                isInvalid={isInvalid}
            >
                {options &&
                    <>
                        <option key='' value=''> </option>
                        {options.map(option =>
                            <option key={option.id} value={option.id}>{display ? option[display] : option.description}</option>)}
                    </>}
            </BootstrapFormControl>
        </BootstrapFormGroup>
    </div>
);

TextField.propTypes = {
    controlId: PropTypes.any,
    text: PropTypes.string,
    type: PropTypes.any,
    placeholder: PropTypes.any,
    as: PropTypes.any,
    row: PropTypes.any,
    onChange: PropTypes.any,
    options: PropTypes.any,
    display: PropTypes.any,
    required: PropTypes.any
}

export default TextField;