const PATH = 'app/App';

export const LOAD_OBJECT_STATUS_REQUEST = `${PATH}LOAD_OBJECT_STATUS_REQUEST`;
export const LOAD_OBJECT_STATUS_SUCCESS = `${PATH}LOAD_OBJECT_STATUS_SUCCESS`;
export const LOAD_UOM_REQUEST = `${PATH}LOAD_UOM_REQUEST`;
export const LOAD_UOM_SUCCESS = `${PATH}LOAD_UOM_SUCCESS`;
export const LOAD_COUNTRY_REQUEST = `${PATH}LOAD_COUNTRY_REQUEST`;
export const LOAD_COUNTRY_SUCCESS = `${PATH}LOAD_COUNTRY_SUCCESS`;
export const LOAD_TENANT_REQUEST = `${PATH}LOAD_TENANT_REQUEST`;
export const LOAD_TENANT_SUCCESS = `${PATH}LOAD_TENANT_SUCCESS`;
export const LOAD_TENANT_USER_REQUEST = `${PATH}LOAD_TENANT_USER_REQUEST`;
export const LOAD_TENANT_USER_SUCCESS = `${PATH}LOAD_TENANT_USER_SUCCESS`;