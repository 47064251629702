import React from 'react';
import './App.css';
import { HashRouter, Route, Switch } from 'react-router-dom';
// import SignIn from './component/auth/SignIn';
// import ForgotPassword from './component/auth/ForgotPassword';
// import ChangePassword from './component/auth/ChangePassword';
// import Pages from './component/pages/pages';
import Customer from './component/pages/Customer';

function App() {
  return (
    <HashRouter>
    <div className="App">
      <Switch>
      <Route exact path='/:id' component={ Customer } />
      {/* <Route exact path='/' component={ Pages } /> */}
        {/* <Route exact path='/' component={ SignIn } />
        <Route path='/forgotPassword' component={ ForgotPassword } />
        <Route path='/changePassword' component={ ChangePassword } /> */}
        {/* <Pages/> */}
      </Switch>
    </div>
    </HashRouter>
  );
}

export default App;
