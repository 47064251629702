import { put, call } from 'redux-saga/effects';
import { getTenantAccountSystem, getTenantBasic, getTenantCorrespondence, getTenantDefault, getTenantFinance, getTenantServiceArea, getTenantServices, updateTenantDetails } from '../service/tenantService';
import * as actions from '../../store/action/tenantAction';
import common, { invalidToken, refeshTokenset } from '../common';
import { saveLoginUser } from '../../store/action/authAction';

export function* loadTenantBasicRequest(data){
    //console.log('loadTenantBasicRequest')
    yield put(actions.loadTenantDetailsError(''))
    try{
      yield put(actions.loadTenantDetailsLoading(true));
      const response = yield call(getTenantBasic,data.payload);
      if(response && response.status && response.status === 200 ){
        //console.log(response.data)
        const tenant = response.data
        yield put(actions.loadTenantBasicSuccess(tenant))
      }
      else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
        yield put(saveLoginUser(false))
        invalidToken()
      } 
      else{
        yield put(actions.loadTenantDetailsLoading(false));
        const massage  =common.error
        yield put(actions.loadTenantDetailsError(massage))
      }  
      if(response && response.data && response.data.newAccessToken){
        refeshTokenset(response.data.newAccessToken)
      }
      yield put(actions.loadTenantDetailsLoading(false));
    }catch(error){
      yield put(actions.loadTenantDetailsLoading(false));
      //console.log(error)
      const massage  =common.error
      yield put(actions.loadTenantDetailsError(massage))
    }
  }

  export function* loadTenantFinanceRequest(data){
    //console.log('loadTenantFinanceRequest')
    yield put(actions.loadTenantDetailsError(''))
    try{
      yield put(actions.loadTenantDetailsLoading(true));
      const response = yield call(getTenantFinance,data.payload);
      if(response && response.status && response.status === 200 ){
        //console.log(response.data)
        const tenant = response.data
        yield put(actions.loadTenantFinanceSuccess(tenant))
      }
      else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
        yield put(saveLoginUser(false))
        invalidToken()
      } 
      else{
        yield put(actions.loadTenantDetailsLoading(false));
        const massage  =common.error
        yield put(actions.loadTenantDetailsError(massage))
      }  
      if(response && response.data && response.data.newAccessToken){
        refeshTokenset(response.data.newAccessToken)
      }
      yield put(actions.loadTenantDetailsLoading(false));
    }catch(error){
      yield put(actions.loadTenantDetailsLoading(false));
      //console.log(error)
      const massage  =common.error
      yield put(actions.loadTenantDetailsError(massage))
    }
  }

export function* loadTenantCorrespondanceRequest(data){
    //console.log('loadTenantCorrespondanceRequest')
    yield put(actions.loadTenantDetailsError(''))
    try{
      yield put(actions.loadTenantDetailsLoading(true));
      const response = yield call(getTenantCorrespondence,data.payload);
      if(response && response.status && response.status === 200 ){
        //console.log(response.data)
        const tenant = response.data
        yield put(actions.loadTenantCorrespondenceSuccess(tenant))
      }
      else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
        yield put(saveLoginUser(false))
        invalidToken()
      } 
      else{
        yield put(actions.loadTenantDetailsLoading(false));
        const massage  =common.error
        yield put(actions.loadTenantDetailsError(massage))
      }  
      if(response && response.data && response.data.newAccessToken){
        refeshTokenset(response.data.newAccessToken)
      }
      yield put(actions.loadTenantDetailsLoading(false));
    }catch(error){
      yield put(actions.loadTenantDetailsLoading(false));
      //console.log(error)
      const massage  =common.error
      yield put(actions.loadTenantDetailsError(massage))
    }
  }

export function* sendUpdateTenantRequest(data){
    //console.log('sendUpdateTenantRequest')
    yield put(actions.loadTenantDetailsError(''))
    try{
      // yield put(actions.sendUpdateInvoiceLoading(true));
      const response = yield call(updateTenantDetails,data.payload);
      if(response && response.data && response.data.statusCode && response.data.statusCode === 200 ){
        //console.log(response.data.result)
        const massage = response.data.message
        yield put(actions.sendTenantDetailsSuccess(massage))
      }
      else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
        yield put(saveLoginUser(false))
        invalidToken()
      } 
      else{
        const massage = response.data.message
        yield put(actions.loadTenantDetailsError(massage))
      }
      if(response && response.data && response.data.result && response.data.result.newAccessToken){
        refeshTokenset(response.data.result.newAccessToken)
      }
      // yield put(actions.sendUpdateInvoiceLoading(false));
    }catch(error){
      // yield put(actions.sendUpdateInvoiceLoading(false));
      //console.log(error.response)
      const massage  =common.error
      yield put(actions.loadTenantDetailsError(massage))
    }
  }

  export function* loadTenantDefaultRequest(data){
    //console.log('loadTenantDefaultRequest')
    // yield put(actions.loadTenantDetailsError(''))
    try{
      // yield put(actions.loadTenantDetailsLoading(true));
      const response = yield call(getTenantDefault,data.payload);
      if(response && response.data && response.data.statusCode && response.data.statusCode === 200 ){
        //console.log(response.data)
        const tenantDefault = response.data.result.result
        yield put(actions.loadTenantDefaultSuccess(tenantDefault))
      }
      else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
        yield put(saveLoginUser(false))
        invalidToken()
      } 
      // else{
      //   // yield put(actions.loadTenantDetailsLoading(false));
      //   const massage  =common.error
      //   //console.log(massage)
      //   // yield put(actions.loadTenantDetailsError(massage))
      // }  
      if(response && response.data && response.data.newAccessToken){
        refeshTokenset(response.data.newAccessToken)
      }
      // yield put(actions.loadTenantDetailsLoading(false));
    }catch(error){
      // yield put(actions.loadTenantDetailsLoading(false));
      //console.log(error)
      const massage  =common.error
      console.log(massage)
      // yield put(actions.loadTenantDetailsError(massage))
    }
  }

  export function* loadTenantAccountSystemRequest(data){
    //console.log('loadTenantAccountSystemRequest')
    // yield put(actions.loadTenantDetailsError(''))
    try{
      // yield put(actions.loadTenantDetailsLoading(true));
      const response = yield call(getTenantAccountSystem,data.payload);
      if(response && response.status && response.status === 200 ){
        //console.log(response.data)
        const tenantDefault = response.data
        yield put(actions.loadTenantAccountSystemSuccess(tenantDefault))
      }
      else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
        yield put(saveLoginUser(false))
        invalidToken()
      } 
      else{
        // yield put(actions.loadTenantDetailsLoading(false));
        const massage  =common.error
        console.log(massage)
        // yield put(actions.loadTenantDetailsError(massage))
      }  
      if(response && response.data && response.data.newAccessToken){
        refeshTokenset(response.data.newAccessToken)
      }
      // yield put(actions.loadTenantDetailsLoading(false));
    }catch(error){
      // yield put(actions.loadTenantDetailsLoading(false));
      //console.log(error)
      const massage  =common.error
      console.log(massage)
      // yield put(actions.loadTenantDetailsError(massage))
    }
  }


  export function* loadTenantServicesRequest(){
    //console.log('loadTenantCorrespondanceRequest')
    yield put(actions.loadTenantDetailsError(''))
    try{
      yield put(actions.loadTenantDetailsLoading(true));
      const response = yield call(getTenantServices);
      if(response && response.status && response.status === 200 ){
        //console.log(response.data)
        const tenant = response.data.result.result.$values
        yield put(actions.loadTenantServicesSuccess(tenant))
      }
      else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
        yield put(saveLoginUser(false))
        invalidToken()
      } 
      else{
        yield put(actions.loadTenantDetailsLoading(false));
        const massage  =common.error
        yield put(actions.loadTenantDetailsError(massage))
      }  
      if(response && response.data && response.data.newAccessToken){
        refeshTokenset(response.data.newAccessToken)
      }
      yield put(actions.loadTenantDetailsLoading(false));
    }catch(error){
      yield put(actions.loadTenantDetailsLoading(false));
      //console.log(error)
      const massage  =common.error
      yield put(actions.loadTenantDetailsError(massage))
    }
  }

  

  export function* loadTenantServicesAreaRequest(){
    //console.log('loadTenantCorrespondanceRequest')
    yield put(actions.loadTenantDetailsError(''))
    try{
      yield put(actions.loadTenantDetailsLoading(true));
      const response = yield call(getTenantServiceArea);
      if(response && response.status && response.status === 200 ){
        //console.log(response.data)
        const tenant = response.data.result.result.$values
        yield put(actions.loadTenantServicesAreaSuccess(tenant))
      }
      else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
        yield put(saveLoginUser(false))
        invalidToken()
      } 
      else{
        yield put(actions.loadTenantDetailsLoading(false));
        const massage  =common.error
        yield put(actions.loadTenantDetailsError(massage))
      }  
      if(response && response.data && response.data.newAccessToken){
        refeshTokenset(response.data.newAccessToken)
      }
      yield put(actions.loadTenantDetailsLoading(false));
    }catch(error){
      yield put(actions.loadTenantDetailsLoading(false));
      //console.log(error)
      const massage  =common.error
      yield put(actions.loadTenantDetailsError(massage))
    }
  }