import React from 'react';
import BootstrapButton from 'react-bootstrap/cjs/Button';
import "@ui5/webcomponents-icons/dist/begin";
import "@ui5/webcomponents-icons/dist/open-command-field";
import "@ui5/webcomponents-icons/dist/close-command-field";
import 'bootstrap/dist/css/bootstrap.min.css';
import PropTypes from 'prop-types';
import './form.css';

const Button = ({
    variant,
    text,
    onClick,
    type,
    icon,
    color,
    width,
    borderRadius,
    ...props
}) => (
    <div style={{ maxWidth: "250px", minWidth: '120px', width: width, justifyContent: "center", margin: '10px 10px 0 10px' }}>
        <BootstrapButton
            variant={variant}
            onClick={onClick}
            type={type}
            {...props}
            style={{ color: '#ffffff', width: '100%', fontSize: "1rem", backgroundColor: '#262262', padding: '10px', gap: '10px', borderRadius: borderRadius? borderRadius: '8px' }}
            className="button"
            size="lg"
        >
            {icon ?
                <span>
                    <ui5-icon class="samples-margin" name={icon} id="btn-icon"></ui5-icon> {text}
                </span> : text}
        </BootstrapButton>
    </div>
);

Button.propTypes = {
    variant: PropTypes.any,
    text: PropTypes.string,
    onClick: PropTypes.any,
    type: PropTypes.any,
    icon: PropTypes.any,
    color: PropTypes.any,
}

export default Button;