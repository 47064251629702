import React from 'react';
import PropTypes from 'prop-types';
import './pages.css';
import TextField from '../layout/form/TextField';
import common from '../common';
import Button from '../layout/form/Button';
import PhoneNumberField from '../layout/form/PhoneNumberField';

const Email = ({
    handleChange,
    email,
    backOnClick,
    nextOnClick,
    isEmailInValid,
    mobileNumChange,
    mobile,
    isMobileInValid, 
    businessName
}) => (
    <div className='content email'>
        <div className='header-titile'>
            {businessName ? businessName.toUpperCase() : null}
        </div>
        <div className='start-content'>
            Customer Check In
        </div>
        <div className='row'>
            <div className='col-sm'>
                <TextField
                    text="Email Address"
                    controlId="email"
                    // placeholder="Email Address"
                    type="email"
                    onChange={handleChange}
                    defaultValue={email}
                    maxLength={common.maxLengths.customerEmail}
                    isInvalid={isEmailInValid}
                />
            </div>
        </div>
        <div className='row'>
            <div className='col-sm'>
                <PhoneNumberField
                    text="Phone Number"
                    controlId="mobile"
                    type="text"
                    value={mobile}
                    maxLength={common.maxLengths.customerMobile}
                    hint="Please enter valid mobile number (eg:- +xxxxxxxxxx)"
                    onChange={mobileNumChange}
                    isInvalid={isMobileInValid}
                />
            </div>
        </div>
        <div className='footer-button'>
            <Button
                text="Back"
                variant="outline-primary"
                onClick={backOnClick}
            />
            <Button
                text="Next"
                variant="outline-primary"
                onClick={nextOnClick}
                disabled={email === '' || email === null || mobile === '' || mobile === null}
            />
        </div>
        <div className='step-bar'>
            <div className='step-bar-title'>Contact...</div>
            <div className='dot-bar'>
                <div className='prev-dot'></div>
                <div className='active-dot'></div>
                <div className='next-dot'></div>
                <div className='next-dot'></div>
                <div className='next-dot'></div>
            </div>
        </div>
    </div>
);

Email.propTypes = {
    handleChange: PropTypes.any,
    email: PropTypes.any,
}

export default Email;