import { createSelector } from 'reselect';

const selectVehicleDomain = state => state.vehicle;


const makeSelectVehicleList = () =>
  createSelector(
    selectVehicleDomain,
    vehicleState => 
      vehicleState ? vehicleState.get('vehicleList') : []
  );

const makeSelectMakeList = () =>
  createSelector(
    selectVehicleDomain,
    vehicleState => 
    vehicleState ? vehicleState.get('makeList') : []
  );

const makeSelectModelList = () =>
  createSelector(
    selectVehicleDomain,
    vehicleState => 
    vehicleState ? vehicleState.get('modelList') : []
  );

const makeSelectSelectedVehicleSuccess = () =>
  createSelector(
    selectVehicleDomain,
    vehicleState => 
    vehicleState ? vehicleState.get('selectedVehicle') : {}
  );

export {
    makeSelectVehicleList,
    makeSelectMakeList,
    makeSelectModelList,
    makeSelectSelectedVehicleSuccess
}
