import { fromJS } from 'immutable';
import {
  LOAD_CUSTOMER_LIST_ERROR,
  LOAD_CUSTOMER_LIST_LOADING,
  LOAD_CUSTOMER_LIST_SUCCESS,
  LOAD_SELECTED_CUSTOMER_SUCCESS,
  SAVE_IS_CUSTOMER_MODAL_OPEN_STATUS,
  SEND_CUSTOMER_SUCCESS,
} from '../constant/customerConstant';

export const initialState = fromJS({
    isAddNewCustomerModalOpen:false,
    customerList:[],
    isCustomerListLoading:false,
    customerListError:null,
    customerSuccess:null,
    selectedCustomer:''
});

function customerReducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_IS_CUSTOMER_MODAL_OPEN_STATUS:
      return state.set('isAddNewCustomerModalOpen', action.open)
    case LOAD_CUSTOMER_LIST_SUCCESS:
      return state.set('customerList', action.payload)
    case LOAD_CUSTOMER_LIST_LOADING:
      return state.set('isCustomerListLoading', action.payload)
    case LOAD_CUSTOMER_LIST_ERROR:
      return state.set('customerListError', action.payload)
    case SEND_CUSTOMER_SUCCESS:
      return state.set('customerSuccess', action.payload)
    case LOAD_SELECTED_CUSTOMER_SUCCESS:
      return state.set('selectedCustomer', action.payload)
    default:
      return state;
  }
}

export default customerReducer;
