import { 
    LOAD_VEHICLE_LIST_REQUEST,
    LOAD_VEHICLE_LIST_SUCCESS,
    LOAD_MAKE_LIST_SUCCESS,
    LOAD_MAKE_LIST_REQUEST,
    LOAD_MODEL_LIST_REQUEST,
    LOAD_MODEL_LIST_SUCCESS,
    SEND_ADD_NEW_VEHICLE_REQUEST,
    SEND_UPDATE_VEHICLE_REQUEST,
    LOAD_SELECTED_VEHICLE_SUCCESS,
    LOAD_SELECTED_VEHICLE_REQUEST
    } from '../constant/vehicleConstant';
   
  
    export function loadVehicleListRequest(data) {
      return {
        type: LOAD_VEHICLE_LIST_REQUEST,
        payload: data,
      }
    };
      
    export function loadVehicleListSuccess(data) {
      return {
        type: LOAD_VEHICLE_LIST_SUCCESS,
        payload: data,
      }
    };

    export function sendAddNewVehicleRequest(data) {
      return {
        type: SEND_ADD_NEW_VEHICLE_REQUEST,
        payload: data,
      }
    };
    
    export function sendUpdateVehicleRequest(data) {
      return {
        type: SEND_UPDATE_VEHICLE_REQUEST,
        payload: data,
      }
    };

    export function loadMakeListRequest(data) {
      return {
        type: LOAD_MAKE_LIST_REQUEST,
        payload: data,
      }
    };

    export function loadMakeListSuccess(data) {
      return {
        type: LOAD_MAKE_LIST_SUCCESS,
        payload: data,
      }
    };

    
  export function loadModelListRequest(data) {
    return {
      type: LOAD_MODEL_LIST_REQUEST,
      payload: data,
    }
  };

  export function loadModelListSuccess(data) {
    return {
      type: LOAD_MODEL_LIST_SUCCESS,
      payload: data,
    }
  };

  
  export function loadSelectedVehicleSuccess(data) {
    return {
      type: LOAD_SELECTED_VEHICLE_SUCCESS,
      payload: data,
    }
  };

  export function loadSelectedVehicleRequest(data) {
    return {
      type:LOAD_SELECTED_VEHICLE_REQUEST,
      payload: data,
    }
  };
    