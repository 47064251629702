import React, { Component } from 'react'
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import './pages.css';
// import Stepper from './Stepper';
import Cookies from 'js-cookie';
// import Loader from '../../../layout/form/Loader';
import { makeSelectTenantBasic } from '../../store/selector/tenantSelector';
import * as selectors from '../../store/selector/customerSelector';
import * as actions from '../../store/action/customerAction';
import VemasLogo from '../../images/VEMAS.png';
import "@ui5/webcomponents-icons/dist/log";
// import ErrorModal from '../layout/form/errorModal';
import Start from './Start';
import Email from './Email';
// import Mobile from './Mobile';
import Name from './Name';
import Confirm from './Confirm';
import Success from './Success';
import { saveLoginUser } from '../../store/action/authAction';
import Vehicle from './Vehicle';
import { loadMakeListRequest, loadModelListRequest, loadModelListSuccess, loadVehicleListRequest, loadVehicleListSuccess } from '../../store/action/vehicleAction';
import { makeSelectMakeList, makeSelectModelList, makeSelectSelectedVehicleSuccess, makeSelectVehicleList } from '../../store/selector/vehicleSelector';
import { loadTenantBasicRequest } from '../../store/action/tenantAction';


function openInNewTab(url) {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}

class Customer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: '',
      inProcess: false,
      completedVehicle: false,
      completedVehicleDetails: false,
      completedEmail: false,
      completedMobile: false,
      completedName: false,
      completedConfirm: false,
      email: '',
      vehicleRegNo: '',
      make: '',
      model: '',
      mobile: '',
      firstName: '',
      lastName: '',
      displayName: '',
      companyName: '',
      isExistCustomer: false,
      isExistVehicle: false,
      customerDetails: {},
      isVehicleRegVaild: false,
      isEmailInValid: false,
      isMobileInValid: false,
      isDisplayNameInValid: false,
      isSuccess: false
    }
  }

  // static getDerivedStateFromProps(prevProps,prevState) {

  //     return null
  // }

  handleSignOut = () => {
    Cookies.remove("user")
    this.props.dispatch(saveLoginUser(false))
    sessionStorage.removeItem('accessToken')
    localStorage.removeItem('user')
    window.location.reload('/');
  }

  handleChange = (e) => {
    var display = this.state.displayName
    if (e.target.id === "firstName") {
      display = e.target.value + " " + this.state.lastName
    }

    if (e.target.id === "lastName") {
      display = this.state.firstName + " " + e.target.value
    }

    if (e.target.id === "displayName") {
      display = e.target.value
    }

    if (e.target.id === "companyName" && this.state.firstName === "" && this.state.lastName === "") {
      display = e.target.value
    }
    this.setState({
      [e.target.id]: e.target.value,
      displayName: display,
      isEmailInValid: false,
      isMobileInValid: false,
      isDisplayNameInValid: false,
    })
  }

  mobileNumChange = (e) => {
    this.setState({
      mobile: e
    })
  }

  handleChangeMake = (value) => {
    const { match: { params } } = this.props;
    this.props.dispatch(loadModelListSuccess([]))
    this.setState({ make: value })
    const parameters = {
      pageSize: 9999,
      pageNumber: '',
      name: '',
      makeId: value,
      tenantId: params.id
    }
    this.props.dispatch(loadModelListRequest(parameters))
  }

  handleChangeModel = (value) => {
    this.setState({ model: value })
  }

  backToStart = () => {
    this.setState({
      step: '',
      inProcess: false,
      completedVehicle: false,
      completedVehicleDetails: false,
      completedEmail: false,
      completedMobile: false,
      completedName: false,
      completedConfirm: false,
      email: '',
      vehicleRegNo: '',
      make: '',
      model: '',
      mobile: '',
      firstName: '',
      lastName: '',
      displayName: '',
      companyName: '',
    })
  }

  vehicleStep = () => {
    this.setState({
      step: 'vehicleDetails',
      inProcess: true,
      completedVehicle: false,
      completedVehicleDetails: false,
      completedEmail: false,
      completedMobile: false,
      completedName: false,
      completedConfirm: false,
      vehicleRegNo: this.state.vehicleRegNo,
      make: '',
      model: '',
      email: '',
      mobile: '',
      firstName: '',
      lastName: '',
      displayName: '',
      companyName: '',
    })
  }

  vehicleDetailsValidateStep = () => {
    const { match: { params } } = this.props;
    const parameters = {
      pageSize: 20,
      pageNumber: 1,
      Customer: '',
      Make: '',
      Model: '',
      Search: this.state.vehicleRegNo,
      Status: '',
      commonSearch: '',
      tenantId: params.id
    }
    this.props.dispatch(loadVehicleListRequest(parameters))
    this.setState({
      step: 'vehicleDetails',
      inProcess: true,
      completedVehicle: true,
      completedVehicleDetails: true,
      completedEmail: false,
      completedMobile: false,
      completedName: false,
      completedConfirm: false,
      vehicleRegNo: this.state.vehicleRegNo,
      make: this.state.make,
      model: this.state.model,
      email: '',
      mobile: '',
      firstName: '',
      lastName: '',
      displayName: '',
      companyName: '',
    })
  }


  firstStep = () => {
    this.setState({
      step: 'email',
      inProcess: true,
      completedVehicle: true,
      completedVehicleDetails: false,
      completedEmail: false,
      completedMobile: false,
      completedName: false,
      completedConfirm: false,
      vehicleRegNo: this.state.vehicleRegNo,
      make: this.state.make,
      model: this.state.model,
      email: this.state.email,
      mobile: '',
      firstName: '',
      lastName: '',
      displayName: '',
      companyName: '',
    })
  }

  secondStep = () => {
    const { match: { params } } = this.props;
    if (this.state.email === '') {
      this.setState({
        isEmailInValid: true
      })
      return
    }
    const parameters = {
      pageSize: 20,
      pageNumber: 1,
      name: '',
      email: this.state.email,
      mobile: '',
      city: '',
      commonSearch: '',
      tenantId: params.id
    }
    this.props.dispatch(actions.loadCustomerListRequest(parameters))
    this.setState({
      step: 'mobile',
      inProcess: true,
      completedVehicle: true,
      completedVehicleDetails: true,
      completedEmail: true,
      completedMobile: false,
      completedName: false,
      completedConfirm: false,
      email: this.state.email,
      vehicleRegNo: this.state.vehicleRegNo,
      make: this.state.make,
      model: this.state.model,
      mobile: this.state.mobile,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      displayName: this.state.displayName,
      companyName: this.state.companyName,
    })
  }

  thirdStep = () => {
    const { match: { params } } = this.props;
    if (this.state.mobile === '') {
      this.setState({
        isMobileInValid: true
      })
      return
    }
    if (!this.state.isExistCustomer) {
      const parameters = {
        pageSize: 20,
        pageNumber: 1,
        name: '',
        email: '',
        mobile: this.state.mobile,
        city: '',
        commonSearch: '',
        tenantId: params.id
      }
      this.props.dispatch(actions.loadCustomerListRequest(parameters))
    }
    this.setState({
      step: 'name',
      inProcess: true,
      completedVehicle: true,
      completedVehicleDetails: true,
      completedEmail: true,
      completedMobile: true,
      completedName: false,
      completedConfirm: false,
      vehicleRegNo: this.state.vehicleRegNo,
      make: this.state.make,
      model: this.state.model,
      email: this.state.email,
      mobile: this.state.mobile,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      displayName: this.state.displayName,
      companyName: this.state.companyName,
    })
  }

  lastStep = () => {
    if (this.state.displayName === '') {
      this.setState({
        isDisplayNameInValid: true
      })
      return
    }
    this.setState({
      step: 'confirm',
      inProcess: true,
      completedVehicle: true,
      completedVehicleDetails: true,
      completedEmail: true,
      completedMobile: true,
      completedName: true,
      completedConfirm: false,
      vehicleRegNo: this.state.vehicleRegNo,
      make: this.state.make,
      model: this.state.model,
      email: this.state.email,
      mobile: this.state.mobile,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      displayName: this.state.displayName,
      companyName: this.state.companyName,
    })
  }

  submitData = () => {
    const { customerDetails } = this.state;
    const { selectedVehicle, match: { params } } = this.props;
    var vehicleParameters = {}
    var parameters = {}
    if (this.state.isExistVehicle) {
      vehicleParameters = {
        isExistVehicle: this.state.isExistVehicle,
        id: selectedVehicle.id,
        vehicleTypeId: selectedVehicle.vehicleTypeId,
        vehicleRegistrationNumber: this.state.vehicleRegNo,
        complianceDate: selectedVehicle.complianceDate,
        builtDate: selectedVehicle.builtDate,
        lastServicedDate: selectedVehicle.lastServicedDate,
        serviceDueDate: selectedVehicle.serviceDueDate,
        serviceDueMillage: selectedVehicle.serviceDueMillage,
        customerId: selectedVehicle.customerId,
        colour: selectedVehicle.colour,
        vehicleIdentificationNo: selectedVehicle.vehicleIdentificationNo,
        makeId: this.state.make,
        modelId: this.state.model,
        modelVariance: selectedVehicle.modelVariance,
        statusId: selectedVehicle.statusId,
        engineNo: selectedVehicle.engineNo,
        transmissionTypeId: selectedVehicle.transmissionTypeId,
        fuelTypeId: selectedVehicle.fuelTypeId,
        tyreSize: selectedVehicle.tyreSize,
        customerField1: selectedVehicle.customerField1,
        customerField2: selectedVehicle.customerField2,
        customerField3: selectedVehicle.customerField3,
        serviceRemindersSent: selectedVehicle.serviceRemindersSent,
        registrationDueDate: selectedVehicle.registrationDueDate,
        numberOfCylinders: selectedVehicle.numberOfCylinders,
        lastServicedMillage: selectedVehicle.lastServicedMillage,
        notes: selectedVehicle.notes,
        tenantId: params.id
      }
    }
    else {
      vehicleParameters = {
        isExistVehicle: this.state.isExistVehicle,
        id: '',
        vehicleTypeId: '',
        vehicleRegistrationNumber: this.state.vehicleRegNo,
        complianceDate: '',
        builtDate: '',
        lastServicedDate: '',
        serviceDueDate: '',
        serviceDueMillage: '',
        customerId: '',
        colour: '',
        vehicleIdentificationNo: '',
        makeId: this.state.make,
        modelId: this.state.model,
        modelVariance: '',
        statusId: '10',
        engineNo: '',
        transmissionTypeId: '',
        fuelTypeId: '',
        tyreSize: '',
        customerField1: '',
        customerField2: '',
        customerField3: '',
        serviceRemindersSent: '',
        registrationDueDate: '',
        numberOfCylinders: '',
        lastServicedMillage: '',
        notes: '',
        tenantId: params.id
      }
    }


    if (this.state.isExistCustomer) {
      const customerParameters = {
        id: customerDetails.id,
        TitleId: customerDetails.titleId,
        FirstName: this.state.firstName,
        MiddleName: customerDetails.middleName,
        LastName: this.state.lastName,
        EmailAddress: this.state.email === '' ? null : this.state.email,
        BusinessName: this.state.companyName,
        FixedPhone: customerDetails.fixedPhone,
        MobilePhone: this.state.mobile,
        Fax: customerDetails.fax,
        DisplayName: this.state.displayName,
        CustomField1: customerDetails.customField1,
        CustomField2: customerDetails.customField2,
        notes: customerDetails.notes,
        ObjectStatus: customerDetails.objectStatus,
        shpStreetNumber: customerDetails.shpStreetNumber,
        shpStreetName: customerDetails.shpStreetName,
        shpCity: customerDetails.shpCity,
        shpPostalCode: customerDetails.shpPostalCode,
        shpRegion: customerDetails.shpRegion,
        shpCountry: customerDetails.shpCountry,
        billStreetNumber: customerDetails.billStreetNumber,
        billStreetName: customerDetails.billStreetName,
        billCity: customerDetails.billCity,
        billPostalCode: customerDetails.billPostalCode,
        billRegion: customerDetails.billRegion,
        billCountry: customerDetails.billCountry,
        tenantId: params.id,
        ignoreExist: true
      }
      parameters = {
        vehicle: vehicleParameters,
        customer: customerParameters
      }
      this.props.dispatch(actions.sendUpdateCustomerRequest(parameters))
    } else {
      const customerParameters = {
        FirstName: this.state.firstName,
        LastName: this.state.lastName,
        EmailAddress: this.state.email === '' ? null : this.state.email,
        BusinessName: this.state.companyName,
        MobilePhone: this.state.mobile,
        DisplayName: this.state.displayName,
        ObjectStatus: 10,
        TitleId: 0,
        MiddleName: '',
        FixedPhone: '',
        Fax: '',
        CustomField1: '',
        CustomField2: '',
        notes: [],
        shpStreetNumber: '',
        shpStreetName: '',
        shpCity: '',
        shpPostalCode: '',
        shpRegion: '',
        shpCountry: { code: '' },
        billStreetNumber: '',
        billStreetName: '',
        billCity: '',
        billPostalCode: '',
        billRegion: '',
        billCountry: { code: '' },
        tenantId: params.id
      }
      parameters = {
        vehicle: vehicleParameters,
        customer: customerParameters
      }
      this.props.dispatch(actions.sendAddNewCustomerRequest(parameters))
    }
    this.setState({
      isSuccess: true,
      inProcess: false
    })
  }

  errorModalClose = () => {
    this.props.dispatch(actions.loadCustomerListError(null))
  }

  successClose = () => {
    this.props.dispatch(actions.sendCustomerSuccess(null))
    this.setState({
      step: '',
      inProcess: false,
      completedVehicle: false,
      completedVehicleDetails: false,
      completedEmail: false,
      completedMobile: false,
      completedName: false,
      completedConfirm: false,
      vehicleRegNo: '',
      make: '',
      model: '',
      email: '',
      mobile: '',
      firstName: '',
      lastName: '',
      displayName: '',
      companyName: '',
      isExistCustomer: false,
      isExistVehicle: false,
      customerDetails: {},
      isEmailInValid: false,
      isMobileInValid: false,
      isDisplayNameInValid: false,
      isSuccess: false
    })
  }

  componentDidMount() {
    const { match: { params } } = this.props;
    const parameters = {
      pageSize: 9999,
      pageNumber: '',
      description: '',
      name: '',
      email: '',
      mobile: '',
      city: '',
      makeId: '',
      commonSearch: '',
      tenantId: params.id
    }
    this.props.dispatch(loadMakeListRequest(parameters))
    this.props.dispatch(loadTenantBasicRequest(params.id));
  }

  componentDidUpdate(prevProps, prevState) {
    const { customerList, selectedCustomer, vehicleList, match: { params } } = this.props;
    if (customerList && customerList.length && customerList.length > 0) {
      const parameters = {
        customerId: customerList[0].id,
        tenantId: params.id
      }
      this.props.dispatch(actions.loadCustomerListSuccess([]))
      this.props.dispatch(actions.loadSelectedCustomerRequest(parameters))
      this.setState({
        isExistCustomer: true
      })
    }
    if (selectedCustomer && selectedCustomer.id && selectedCustomer.id > 0) {
      this.setState({
        email: selectedCustomer.emailAddress,
        mobile: selectedCustomer.mobilePhone,
        firstName: selectedCustomer.firstName,
        lastName: selectedCustomer.lastName,
        displayName: selectedCustomer.displayName,
        companyName: selectedCustomer.businessName,
        customerDetails: selectedCustomer
      })
      this.props.dispatch(actions.loadSelectedCustomerSuccess(''))
    }

    if (vehicleList && vehicleList.length && vehicleList.length > 0) {
      const vehicle = vehicleList[0];
      this.props.dispatch(loadVehicleListSuccess([]))
      this.setState({
        isExistVehicle: true,
        make: vehicle.makeId,
        model: vehicle.modelId
      })
    }
    if (this.state.isSuccess === true) {
      setTimeout(
        () => this.successClose(),
        2500
      );
    }
  }

  render() {
    const {
      tenantDetails,
      makeList,
      modelList
      // customerListError,
      // customerSuccess
    } = this.props;

    const {
      inProcess,
      step,
      // completedVehicle,
      completedVehicleDetails,
      // completedEmail,
      // completedMobile,
      // completedName,
      // completedConfirm,
      isEmailInValid,
      isMobileInValid,
      isDisplayNameInValid,
      isSuccess
    } = this.state;

    return (
      <div>
        {/* <div className='logOut'>
              <div onClick={this.handleSignOut}>
                <ui5-icon class="samples-margin" name="log" id="logOut-button"></ui5-icon>
              </div>
            </div> */}
        <div className='customer-content'>
          {/* <div className='header'>
                <h3>{tenantDetails.businessName}</h3>
                <h4>Customer check in</h4>
              </div> */}
          {inProcess ?
            <div>
              {/* <Stepper
                  active={step}
                  completedVehicle={completedVehicle}
                  completedEmail={completedEmail}
                  completedMobile={completedMobile}
                  completedName={completedName}
                  completedConfirm={completedConfirm}
                /> */}
              {step === 'vehicleDetails' &&
                <Vehicle
                  handleChange={this.handleChange}
                  vehicleRegNo={this.state.vehicleRegNo}
                  make={this.state.make}
                  model={this.state.model}
                  backOnClick={this.backToStart}
                  nextOnClick={this.vehicleDetailsValidateStep}
                  isInvalid={isEmailInValid}
                  completedVehicleDetails={completedVehicleDetails}
                  nextOnClickSecond={this.firstStep}
                  backOnClickSecond={this.vehicleStep}
                  makeList={makeList}
                  handleChangeMake={this.handleChangeMake}
                  modelList={modelList}
                  handleChangeModel={this.handleChangeModel}
                  businessName={tenantDetails && tenantDetails.businessName ? tenantDetails.businessName : null}
                />
              }
              {step === 'email' &&
                <Email
                  handleChange={this.handleChange}
                  email={this.state.email}
                  backOnClick={this.vehicleDetailsValidateStep}
                  nextOnClick={this.thirdStep}
                  isEmailInValid={isEmailInValid}
                  mobileNumChange={this.mobileNumChange}
                  mobile={this.state.mobile}
                  isMobileInValid={isMobileInValid}
                  businessName={tenantDetails && tenantDetails.businessName ? tenantDetails.businessName : null}
                />
              }
              {/* {step === 'mobile' &&
                  <Mobile
                    handleChange={this.mobileNumChange}
                    mobile={this.state.mobile}
                    backOnClick = {this.firstStep}
                    nextOnClick = {this.thirdStep}
                    isInvalid={ isMobileInValid }
                  />
                } */}
              {step === 'name' &&
                <Name
                  handleChange={this.handleChange}
                  data={this.state}
                  backOnClick={this.firstStep}
                  nextOnClick={this.lastStep}
                  isInvalid={isDisplayNameInValid}
                  businessName={tenantDetails && tenantDetails.businessName ? tenantDetails.businessName : null}
                />
              }
              {step === 'confirm' &&
                <Confirm
                  data={this.state}
                  backOnClick={this.thirdStep}
                  nextOnClick={this.submitData}
                  businessName={tenantDetails && tenantDetails.businessName ? tenantDetails.businessName : null}
                />

              }
            </div> : isSuccess ?
              <Success
                tenantDetails={tenantDetails} /> :
              <div >
                <Start
                  onClick={this.vehicleStep}
                  buttonText="GET STARTED"
                  tenantDetails={tenantDetails}
                />
              </div>
          }
          <div className='footer'>
            <div><small>Powered by </small></div>
            <img onClick={() => { openInNewTab('https://vemas.com.au/') }} alt="vemaslogo_footer" id="vemaslogo_footer" src={VemasLogo} />
          </div>
        </div>
      </div>
    )
  }
}

Customer.propTypes = {
  tenantDetails: PropTypes.any,

}

const mapStateToProps = createStructuredSelector({
  tenantDetails: makeSelectTenantBasic(),
  customerList: selectors.makeSelectCustomerList(),
  selectedCustomer: selectors.makeSelectSelectedCustomerSuccess(),
  customerListError: selectors.makeSelectCustomerListError(),
  customerSuccess: selectors.makeSelectCustomerSuccess(),
  vehicleList: makeSelectVehicleList(),
  selectedVehicle: makeSelectSelectedVehicleSuccess(),
  makeList: makeSelectMakeList(),
  modelList: makeSelectModelList(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);


export default (compose(withConnect)(Customer));
