import React from 'react';
import PropTypes from 'prop-types';
import './pages.css';
import Button from '../layout/form/Button';
import VemasLogo from '../../images/check-circle-fill.png';

const Confirm = ({
    data,
    backOnClick,
    nextOnClick,
    businessName
}) => (
    <div className='content'>
        <div className='header-titile'>
            {businessName ? businessName.toUpperCase() : null}
        </div>
        <div className='start-content'>
            Customer Check In
        </div>
        <div className='confirm-details'>
            <div>
                <div className='confirm-details-title'>Vehicle registration number</div>
                <div className='confirm-details-detail'>{data.vehicleRegNo}</div>
            </div>
            <div>
                <img alt="confirm-correct-icon" id="confirm-correct-icon" src={VemasLogo} />
            </div>
        </div>
        <div className='confirm-details'>
            <div>
                <div className='confirm-details-title'>Email Address</div>
                <div className='confirm-details-detail'>{data.email}</div>
            </div>
            <div>
                <img alt="confirm-correct-icon" id="confirm-correct-icon" src={VemasLogo} />
            </div>
        </div>
        <div className='confirm-details'>
            <div >
                <div className='confirm-details-title'>Mobile</div>
                <div className='confirm-details-detail'>{data.mobile}</div>
            </div>
            <div>
                <img alt="confirm-correct-icon" id="confirm-correct-icon" src={VemasLogo} />
            </div>
        </div>
        <div className='confirm-details'>
            <div >
                <div className='confirm-details-title'>First Name</div>
                <div className='confirm-details-detail'>{data.firstName}</div>
            </div>
            <div>
                <img alt="confirm-correct-icon" id="confirm-correct-icon" src={VemasLogo} />
            </div>
        </div>
        <div className='confirm-details'>
            <div >
                <div className='confirm-details-title'>Last Name</div>
                <div className='confirm-details-detail'>{data.lastName}</div>
            </div>
            <div>
                <img alt="confirm-correct-icon" id="confirm-correct-icon" src={VemasLogo} />
            </div>
        </div>
        <div className='confirm-details'>
            <div>
                <div className='confirm-details-title'>Display Name</div>
                <div className='confirm-details-detail'>{data.displayName}</div>
            </div>
            <div>
                <img alt="confirm-correct-icon" id="confirm-correct-icon" src={VemasLogo} />
            </div>
        </div>
        <div className="confirm-details">
            <div>
                <div className='confirm-details-title'>Company Name</div>
                <div className='confirm-details-detail'>{data.companyName}</div>
            </div>
            <div>
                <img alt="confirm-correct-icon" id="confirm-correct-icon" src={VemasLogo} />
            </div>
        </div>
        <div className='footer-button'>
            <Button
                text="Back"
                variant="outline-primary"
                onClick={backOnClick}
            />
            <Button
                text="Submit"
                variant="outline-primary"
                onClick={nextOnClick}
            />
        </div>
        <div className='step-bar' style={{ marginTop: '-150px' }}>
            <div className='step-bar-title'>Confirm...</div>
            <div className='dot-bar'>
                <div className='prev-dot'></div>
                <div className='prev-dot'></div>
                <div className='prev-dot'></div>
                <div className='active-dot'></div>
                <div className='next-dot'></div>
            </div>
        </div>
    </div>
);

Confirm.propTypes = {
    data: PropTypes.any,
}

export default Confirm;